<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Így védekezz a cyberstalking ellen!
          </h2>
          <p class="hashText">- A kevesebb néha több </p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Az internetes zaklatás és a cyberstalking egyre gyakoribb probléma, amely súlyos következményekkel járhat az áldozatokra nézve mind kapcsolati, bizalmi szempontból, mind pedig mentális egészség szintjén. </p>

                <p class="text">
                Képzeld el, hogy egy ismeretlen személy bekövet téged egy online platformon! Elkezdtek beszélgetni, eleinte érdekes, jó fej embernek tűnik, azon kapod magad, hogy egyre több információt osztasz meg vele, legyen szó személyes adatokról vagy mély beszélgetés során felmerülő témákról. Akár más közösségi médiás elérhetőségedet is megadod neki. Egy nap azonban megváltozik a viselkedése: követelőzni kezd, zaklató üzeneteket kezd el küldözgetni, elkezdi közzétenni a személyes fotóidat, információkat oszt meg rólad, és folyamatosan figyelemmel kísér téged az interneten keresztül. Ez a folyamat súlyos stresszt, félelmet és kellemetlenséget okoz neked; megszegi a magánéleted határait. Mit tehetsz? Hogyan tudod ezt kivédeni? <br><br>A digitális korban az online jelenlét és az ott folyó kommunikáció számos előnyt kínál, de sajnos nem mindenki használja ezeket az eszközöket jó szándékkal. Az internetes zaklatás és a cyberstalking egyre gyakoribb probléma, amely súlyos következményekkel járhat az áldozatokra nézve mind kapcsolati, bizalmi szempontból, mind mentális egészség szintjén. <br>Fontos, hogy tisztában legyél a cyberstalking veszélyeivel, és megismerd a védekezés legfontosabb módjait. Íme néhány tanács, hogyan óvhatod magad a cyberstalkingtól.
                </p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/cyber_love.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Védd meg a személyes adatokat:</b> Az egyik legfontosabb lépés a cyberstalking megelőzésében az, hogy óvd és védd a személyes adataidat. Kerüld a túlzottan sokat mondó profilok létrehozását és a személyes információk, mint például a lakcím, a telefonszám vagy a születési dátum megosztását az interneten! Gondold át alaposan, milyen információkat osztasz meg a közösségi média platformokon és más online felületeken! Feltétlenül szükségesek ezen információk megosztása? Mi a célod azzal, hogy ezeket megadod magadról?</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Erősítsd meg az online biztonságodat: </b> Győződj meg róla, hogy erős jelszavakat használsz az online fiókjaidhoz, és rendszeresen változtasd meg őket! Aktiváld a kétlépcsős azonosítási folyamatot, amikor lehetséges, hogy extra védelmet biztosíts magadnak! Használj megbízható és naprakész vírusirtó programokat, és frissítsd rendszeresen az operációs rendszereidet és az alkalmazásaidat!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Állíts be megfelelő adatvédelmi beállításokat:  </b> Tanulmányozd és ellenőrizd az adatvédelmi beállításokat a közösségi média fiókjaidban és más online szolgáltatásokban! Korlátozd, hogy ki láthatja a profilodat és a bejegyzéseidet, és győződj meg róla, hogy csak a megbízható személyekkel osztasz meg személyes információkat!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Legyél körültekintő az ismeretlenekkel való kapcsolatfelvétel során: </b> Légy óvatos, amikor elfogadsz ismerősnek jelöléseket ismeretlen személyektől a közösségi média platformokon vagy más online fórumokon! Ne ossz meg személyes információkat vagy intim részleteket ismeretlenekkel, és gondold át alaposan, mielőtt találkozol velük személyesen! A megelőzés érdekében érdemes ellenőrizni a személyek profiljait, és ha gyanús vagy zavaró viselkedést tapasztalsz, azonnal szakítsd meg a kapcsolatot velük.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Tartsd szem előtt a kiberbiztonsági intézkedéseket: </b> Legyél tisztában azzal, hogy milyen kiberbiztonsági kockázatokkal járhatnak bizonyos online tevékenységek! Ne kattints gyanús linkekre vagy mellékletekre, és ne tölts le kétes eredetű fájlokat ismeretlen forrásokból! Különösen igaz ez az egyébként illegális kalóz oldalakra is, amik  ingyenesen ígérnek máshol megvásárolható tartalmakat, legyen szó filmekről, számítógépes játékokról vagy könyvekről. Figyelj oda az internetes szokásaidra és legyél körültekintő a digitális eszközeid használatakor!</p> 
                </li>
              </ul>
            </p>
            <br><br>
            <p class="text">
                <b>Mit tehetsz tehát, ha cyberstalking áldozata lettél? Cselekedj és kérj segítséget:</b>
              <ul>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>1.	Szakíts meg minden kapcsolatot az illetővel és blokkold a kommunikációs csatornákat!</b></p>
                </li>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>2.	Gyűjts bizonyítékokat a zaklatásról, például ments le az üzeneteket vagy képernyőképeket!</b></p>
                </li>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>3.	Jelentsd az esetet az online platformnak és a helyi hatóságoknak!</b></p>
                </li>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>4.	Kérj segítséget szakemberektől, mint például ügyvédektől, pszichológusoktól vagy a rendőrségtől!</b></p>
                </li>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>5.	Fontos, hogy ne maradj egyedül és keress támogatást az ilyen helyzetekben. A cyberstalking súlyos probléma, és fontos, hogy megvédjük magunkat és másokat is az online zaklatástól!</b></p>
                </li>
              </ul>
            </p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Így védekezz a cyberstalking ellen!", 
        desc: "Az internetes zaklatás és a cyberstalking egyre gyakoribb probléma, amely súlyos következményekkel járhat az áldozatokra nézve mind kapcsolati, bizalmi szempontból, mind pedig mentális egészség szintjén.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Így védekezz a cyberstalking ellen!",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Az internetes zaklatás és a cyberstalking egyre gyakoribb probléma, amely súlyos következményekkel járhat az áldozatokra nézve mind kapcsolati, bizalmi szempontból, mind pedig mentális egészség szintjén."        
    })
  }
}
</script>